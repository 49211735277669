<template>
  <div class="dashboard-container" />
</template>

<script>
export default {
  name: 'dashboard'
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
@media (max-width: 1024px) {
  .chart-wrapper {
    padding: 8px;
  }
}
</style>
